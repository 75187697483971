const API_URL = "https://api.calendar.basayin.webarysites.com/";
//const API_URL = "http://localhost:5048/";

export const loadHebrewHolidays = async (year) => {
    console.log("year", year);
    const response = await fetch(`https://www.hebcal.com/hebcal?v=1&cfg=json&maj=on&min=off&nx=on&year=${year}&month=x&ss=on&mf=on&c=on&geo=geoname&geonameid=3448439&M=on&s=on`);
    const json = await response.json();
    return json;
}

export const loadInternalDonations = async () => {
    const response = await fetch(`${API_URL}Donation/GetDonations`);
    const json = await response.json();
    return json;
}


export const makeDonation = async (body) => {
    const response = await fetch(`${API_URL}Donation/CreateDonation`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return await response.json();
}

export const getDateOverridePrice = async date => {
    const response = await fetch(`${API_URL}Donation/GetDateOverridePrice?date=${date}`);
    const json = await response.json();
    return json;
}

export const getOverridePrices = async () => {
    const response = await fetch(`${API_URL}Donation/GetDateOverridePrices`);
    const json = await response.json();
    return json;
}

export const getDefaultMealPrice = async () => {
    const response = await fetch(`${API_URL}Donation/GetDefaultMealPrice`);
    const json = await response.json();
    return json;
}

export const getDefaultDayPrice = async() => {
    const response = await fetch(`${API_URL}Donation/GetDefaultDayPrice`);
    const json = await response.json();
    return json;
}

export const getDatePrices = async () => {
    const response = await fetch(`${API_URL}Donation/GetDatePrices`);
    const json = await response.json();
    return json;
}

export const addDayPriceRequest = async (body) => {
    const response = await fetch(`${API_URL}Donation/AddDatePrice`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return await response.json();
}

export const addDateOverridePrice = async (body) => {
    const response = await fetch(`${API_URL}Donation/AddDateOverridePrice`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return await response.json();
}

export const updateDefaultMealPrice = async price => {
    const response = await fetch(`${API_URL}Donation/UpdateDefaultMealPrice?price=${price}`, { method: "POST" });
    const json = await response.json();
    return json;
}

export const deleteDateOverridePrice = async id => {
    const response = await fetch(`${API_URL}Donation/DeleteDateOverridePrice?id=${id}`, { method: "POST" });
    const json = await response.json();
    return json;
}