import './calendar.css';

import React, { useEffect, useState } from 'react'
import { Container, Modal, Button, Row, FormGroup, FormLabel, Form, FormControl, Col, FormCheck, Alert, FormSelect } from 'react-bootstrap';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";

import { getDateOverridePrice, getDatePrices, getDefaultDayPrice, loadHebrewHolidays, loadInternalDonations, makeDonation } from './requests';
import { useScreenSize, getAllDatesWithinYear, CAMPAIGN_ID, getDates, convertDateString, getMost } from './utils';

import { EventContent } from './eventContent';
import { gematriya, HDate } from '@hebcal/core';
import { useLocation } from "react-router-dom";
import { hebrewMonthNames, offlineFieldNames, onlineFieldNames } from './constants';
import { ReactJewishDatePicker } from "react-jewish-datepicker";

var allDates = [];

function App() {

  const [validDates, setValidDates] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isAdmin = params.get('is_admin') ?? 0;

  const getFieldName = (name) => {
    return isAdmin == 1 ? offlineFieldNames[name] : onlineFieldNames[name];
  }

  const getFormPostURL = () => {
    return isAdmin == 1 ? `https://basayin.org/my-campaign/?cid=${CAMPAIGN_ID}#tabContentManualDonations?redirectUrl=${encodeURIComponent(window.location.href)}` : "https://basayin.org/";
  }

  const [show, setShow] = useState(false);
  const [date, setDate] = useState();
  const [mealPrice, setMealPrice] = useState();
  const [events, setEvents] = useState([]);
  const [activeYear, setYear] = useState([]);
  const [defaultDayPrice, setDefaultDayPrice] = useState();
  const [dayPrices, setDayPrices] = useState();
  const [basicJewishDay, setBasicJewishDay] = useState();

  // modal props
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [donationText, setDonationText] = useState();
  const [isUpgradeMeal, setIsUpgradeMeal] = useState(false);
  const [publicName, setPublicName] = useState();
  const [attribution, setAttribution] = useState(1);
  const [otherAttribution, setOtherAttribution] = useState();
  const [relationship, setRelationship] = useState(1);
  const [otherRelationship, setOtherRelationship] = useState();
  const [note, setNote] = useState();
  const { isMobile } = useScreenSize();
  // end modal props

  const calendarComponentRef = React.createRef();

  const loadDonations = () => {
    loadInternalDonations().then(response => {
      const donations = response.map(x => ({ ...x, allowDonate: false }));
      let tempEvents = [...donations];

      for (let validDate of validDates) {
        if (tempEvents.find(x => new Date(x.date).toDateString() == validDate.toDateString())) {
          continue;
        }
        tempEvents.push({ date: validDate, _date: validDate, allowDonate: true, ...validDate });
      }

      setEvents(tempEvents);
    });
  }

  useEffect(() => {
    if (calendarComponentRef && calendarComponentRef.current && basicJewishDay) {
      let calendarApi = calendarComponentRef.current.getApi();
      calendarApi.gotoDate(basicJewishDay.date); // call a method on the Calendar object
    }

  }, [basicJewishDay])

  useEffect(() => {
    async function load(year) {
      allDates = getAllDatesWithinYear(year);
      const json = await loadHebrewHolidays(year);
      const defaultDayPrice = await getDefaultDayPrice();
      setDefaultDayPrice(defaultDayPrice.value);

      const { items } = json;

      let invalidDates = items.filter(x =>
        x.title.toLowerCase().includes("candle") || x.title.toLowerCase().includes("havdalah") ||
        (x.category.toLowerCase() == "holiday" && (x.subcat == "major" || x.subcat == "fast")));


      for (let _invalidDate of invalidDates) {
        if (_invalidDate.title.indexOf("Chanukah") > -1) {
          _invalidDate.remove = true;
        }
      }

      invalidDates = invalidDates.filter(x => !x.remove);

      // for(let _d of invalidDates){
      //   console.log(`${_d.date} ${_d.title} ${_d.category || '..'} ${_d.subcat || '..'}  `);
      // }

      for (let date of allDates) {
        let invalidDay = invalidDates.find(x => { /*console.log(x.date, new Date(x.date).toDateString(), "NAREK");*/
          return convertDateString(x.date) == convertDateString(date.toISOString().split('T')[0])  //new Date(x.date).toDateString() == d.toDateString();
        });
        if (invalidDay) {
          date.lunchServed = false;

          if (invalidDay.category.toLowerCase() == "holiday") {
            date.infoText = invalidDay.hebrew || invalidDay.title;
          }
          else {

            if (date.getDay() == 6) {
              const parsha = items.find(x => convertDateString(x.date) == convertDateString(date.toISOString()) && x.category.toLowerCase() == "parashat")?.hebrew;
              date.infoText = parsha;
            }
          }
        } else {
          date.lunchServed = true;
        }
      }
      setValidDates(allDates);
      
      const dayPrices = await getDatePrices();
      setDayPrices(dayPrices);
    }

    if (activeYear && typeof (activeYear) == 'number') {
      load(activeYear);
    }
  }, [activeYear])

  useEffect(() => {
    if (validDates) {
      loadDonations();
    }
  }, [validDates])

  useEffect(() => {
    if (!activeYear) {
      let year = new Date().getFullYear();
      setYear(year);
    }
  }, [])

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setIsUpgradeMeal(false);
    setMealPrice('');
    setNote('');
    setAttribution(1);
    setRelationship(1);
    setPublicName('');
    setValidated(false);
    setShow(false);
  }

  const renderEventInfo = eventInfo => {
    const { event } = eventInfo;
    const hebrewInfo = events.find(x => x.date && x.date == event._def.extendedProps._date && x.lunchServed == false)

    return <EventContent
      isMobile={isMobile}
      events={events}
      hebrewInfo={hebrewInfo}
      defaultDayPrice={defaultDayPrice}
      dayPrices={dayPrices}
      sponsor={event._def.extendedProps.sponsorName}
      team={event._def.extendedProps.team}
      allowDonate={event._def.extendedProps.allowDonate}
      donorName={event._def.extendedProps.donorName}
      donationText={event._def.extendedProps.donationText}
      attribution={event._def.extendedProps.attribution}
      date={event.start}
      dayClickedWrapper={dayClickedWrapper} />
  }

  const dayClickedWrapper = async date => {
    const overridePrice = await getDateOverridePrice(date.toISOString().split("T")[0]);
    const { price } = overridePrice;

    setMealPrice(price);
    setDate(date);
    setShow(true);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(false);
      event.preventDefault();
      event.stopPropagation();
    }

    makeDonationHandler();
    setValidated(true);
  };

  const getTotalAmount = () => {
    let price = dayPrices && Number(dayPrices.find(x => x.date.split("T")[0] == date)?.price ?? defaultDayPrice);
    if (isUpgradeMeal) {
      price = Number(price) + Number(mealPrice);
    }

    return price;
  }

  const makeDonationHandler = async () => {
    let body = {
      date,
      firstName,
      lastName,
      name: publicName,
      attribution: attribution == 4 ? otherAttribution : String(attribution),
      relationship: relationship == 5 ? otherRelationship : String(relationship),
      note,
      phone,
      price: String(getTotalAmount()),
      hebrewDate: date && new HDate(new Date(date)).toString(),
      donorName: null,
      donationText
    };

    await makeDonation(body);
    handleClose();
  }

  return (
    <Container fluid>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit} action={getFormPostURL()} method='POST'>
            <Row>
              <Col md={12}>
                <p>{date && new HDate(new Date(date)).toString()}</p>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                {/* <Form.Group>
                  <FormLabel><b>Sponsorship Details</b></FormLabel>
                  <FormControl></FormControl>
                </Form.Group> */}
              </Col>
              <Col md={6}></Col>
              <Col md={3}>
                <Form.Check checked={isUpgradeMeal} onChange={e => setIsUpgradeMeal(e.target.checked)} label={`Upgrade Meal $(${mealPrice})`}></Form.Check>
                {/* <Alert variant='success'>Description</Alert> */}
              </Col>
            </Row>

            <Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>First Name</Form.Label>
                <Form.Control name={getFieldName("firstName")} required type='text' value={firstName} onChange={e => setFirstName(e.target.value)} />
                <Form.Control.Feedback type='invalid'>Please fill in first name..</Form.Control.Feedback>
              </Form.Group>


              <Form.Group as={Col} md={6}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control name={getFieldName("lastName")} className="prevent-validation" type='text' value={lastName} onChange={e => setLastName(e.target.value)} />
              </Form.Group>

            </Row>

            <Row className='mt-2'>
              <Col md={6}>
                <FormGroup>
                  <FormLabel>Email</FormLabel>
                  <FormControl type='email' name={getFieldName("email")} value={email} onChange={e => setEmail(e.target.value)}></FormControl>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormLabel>Phone</FormLabel>
                  <FormControl type='text' name={getFieldName("phone")} value={phone} onChange={e => setPhone(e.target.value)}></FormControl>
                </FormGroup>
              </Col>
            </Row>

            {/* <Row className='mt-3'>
              <Col md={3}>
                <FormGroup>
                  <FormLabel>Attribution</FormLabel>
                  <FormSelect value={attribution} onChange={e => setAttribution(e.target.value)} type="text" placeholder='In Honor of'>
                    <option value={1}>לע"נ</option>
                    <option value={2}>לזכות</option>
                    <option value={3}>לרפואת</option>
                    <option value={4}>Other</option>
                  </FormSelect>
                  {attribution == "4" && <Form.Control value={otherAttribution} onChange={e => setOtherAttribution(e.target.value)} type="text" placeholder='Other' className="mt-2"></Form.Control>}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <FormLabel>Name (to be Displayed)</FormLabel>
                  <FormControl value={publicName} name={getFieldName("publicName")} onChange={e => setPublicName(e.target.value)} placeholder='Flat, House No:'></FormControl>
                </FormGroup>
              </Col>

              <Col md={3} >
                <FormGroup>
                  <FormLabel>Relationship</FormLabel>
                  <FormSelect value={relationship} onChange={e => setRelationship(e.target.value)} placeholder='Father'>
                    <option value={1}>Father</option>
                    <option value={2}>Mother</option>
                    <option value={3}>Father in law</option>
                    <option value={4}>Mother in law</option>
                    <option value={5}>Other</option>
                  </FormSelect>
                  {relationship == 5 && <FormControl value={otherRelationship} onChange={e => setOtherRelationship(e.target.value)} type="text" placeholder='Other' className="mt-2"></FormControl>}
                </FormGroup>
              </Col>
            </Row> */}

            <Row className='mt-1'>
              <Col md={12}>
                <Form.Label>Donation text</Form.Label>
                <Form.Control name='donation_text' value={donationText} onChange={e => setDonationText(e.target.value)}></Form.Control>
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col md={12}>
                <Form.Label>Note</Form.Label>
                <Form.Control name={getFieldName("note")} as="textarea" value={note} onChange={e => setNote(e.target.value)} rows={3} placeholder='Message' />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className='d-flex flex-row-reverse'>
                <Button type='submit'>
                  Submit <small>offline</small>
                </Button>
                <Button variant="secondary" className="me-2" onClick={handleClose} >
                  Close
                </Button>
              </Col>
            </Row>

            {isAdmin == '0' && (<Form.Control type='hidden' name='external_source' value='sponsor_date_calendar'></Form.Control>)}
            <Form.Control type='hidden' value={getTotalAmount()} name={getFieldName("price")}></Form.Control>
            <Form.Control type='hidden' value={date && date.toISOString().split("T")[0]} name={getFieldName("dateEn")}></Form.Control>
            <Form.Control type='hidden' value={date && new HDate(new Date(date)).toString()} name={getFieldName("dateHe")}></Form.Control>
          </Form>
        </Modal.Body>
      </Modal>

      <Row className='mt-2'>
        <Col md={12} xs={12} >
          <ReactJewishDatePicker
            value={new Date()}
            isHebrew
            onClick={(day) => {
              setBasicJewishDay(day);
            }}
          />
          <FullCalendar
            //dayHeaderContent={(arg) => dayNames[arg.text]}
            plugins={[dayGridPlugin, interactionPlugin]}
            //locale={heLocale}
            initialView="dayGridMonth"
            //timeZone='America/New_York'
            firstDay={0}
            contentHeight={910}
            eventContent={renderEventInfo}
            // dayCellContent={dayCellContextRenderer}
            direction={'ltr'}
            events={events}
            ref={calendarComponentRef}
            datesSet={(payload) => {
              let startHebMonth = hebrewMonthNames[HDate.monthFromName(new HDate(payload.start).getMonthName()) - 1];
              let endHebMonth = hebrewMonthNames[HDate.monthFromName(new HDate(payload.end).getMonthName()) - 1];
              let year = activeYear;

              let datesRangeDays = getDates(payload.start, payload.end)
              let datesRange = datesRangeDays.map(x => x.getFullYear());

              let activeMonth = new Date(activeYear, getMost(datesRangeDays.map(x => x.getMonth())), 1).toLocaleString('default', { month: 'long' });

              if (payload.start.getFullYear() != activeYear || payload.end.getFullYear() != activeYear) {
                let startYearNumDays = datesRange.filter(x => x == payload.start.getFullYear());
                let endYearNumDays = datesRange.filter(x => x == payload.end.getFullYear());

                if (startYearNumDays.length > endYearNumDays.length) {
                  year = payload.start.getFullYear();
                  setYear(year);
                }
                else {
                  year = payload.end.getFullYear();
                  setYear(year);
                }
              }

              let hebrewYear = gematriya(new HDate(payload.start).getFullYear());
              let headerElement = document.getElementsByClassName("fc-toolbar-title")[0];
              headerElement.innerHTML = `${activeMonth} ${year} - ${startHebMonth} / ${endHebMonth} ${hebrewYear}`;
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
