import { useEffect, useState } from "react";
import { Card, Container, Row, Col, FormControl, FormGroup, Button, InputGroup, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { addDateOverridePrice, getDefaultMealPrice, getOverridePrices, updateDefaultMealPrice, deleteDateOverridePrice, getDatePrices, addDayPriceRequest } from "../requests";


function Settings() {

    const [defaultMealPrice, setDefaultMealPrice] = useState();
    const [overridePrices, setOverridePrices] = useState();
    const [dayPrices, setDayPrices] = useState();

    const [addOverrideUpgradeMealDate, setAddOverrideUpgradeMealDate] = useState();
    const [addOverrideUpgradeMealPrice, setAddOverrideUpgradeMealPrice] = useState();

    const [addDayPriceDate, setAddDayPriceDate] = useState();
    const [addDayPrice, setAddDayPrice] = useState();

    async function load() {
        const overridePrices = await getOverridePrices();
        setOverridePrices(overridePrices);
        console.log(overridePrices, "Override prices");

        const defaultMealPrice = await getDefaultMealPrice();
        setDefaultMealPrice(defaultMealPrice.value);
        console.log(defaultMealPrice, "default meal price");

        const dayPrices = await getDatePrices();
        setDayPrices(dayPrices);
        console.log(dayPrices, "day prices")
    }

    useEffect(() => {
        load();
    }, []);

    const saveDefaultMealPrice = async () => {
        await updateDefaultMealPrice(defaultMealPrice);
    }

    const deleteDateOverridePriceHandler = async (id) => {
        await deleteDateOverridePrice(id);
        await load();
    }

    const addDateOverridePriceHandler = async () => {
        const body = {
            date: addOverrideUpgradeMealDate, 
            price: addOverrideUpgradeMealPrice
        };

        await addDateOverridePrice(body);
        await load();
        setAddOverrideUpgradeMealDate('');
        setAddOverrideUpgradeMealPrice('');
    }

    const deleteDayPriceHandler = async (id) => {
        // call api
        await load();
    }

    const addDayPriceHandler = async () => {
        const body = {
            date: addDayPriceDate,
            price: addDayPrice
        };

        await addDayPriceRequest(body);
        await load();

        setAddDayPriceDate('');
        setAddDayPrice('');
    }

    return (
        <Container fluid>
            <Row className="mt-2">
                <h4 className="text-center">Calendar settings</h4>
            </Row>

            <Row>
                <Col md={3}>
                    <Card>
                        <CardHeader>Default upgrade meal price</CardHeader>
                        <Card.Body>
                            <FormGroup>
                                <FormControl type="text" value={defaultMealPrice} onChange={e => setDefaultMealPrice(e.target.value)}></FormControl>
                                <Button className="mt-2" onClick={saveDefaultMealPrice}>Apply</Button>
                            </FormGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <CardHeader>
                            Override prices per day
                        </CardHeader>
                        <Card.Body>
                            <Row>
                                <h6 className="text-secondary text-center">Add/Update Override upgrade meal price</h6>
                                <Col md={5}>
                                    <FormGroup>
                                        <FormControl value={addOverrideUpgradeMealDate} onChange={e => setAddOverrideUpgradeMealDate(e.target.value)} type='date' placeholder="Date"></FormControl>
                                    </FormGroup>
                                </Col>
                                <Col md={5}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                        <Form.Control
                                            value={addOverrideUpgradeMealPrice}
                                            onChange={e => setAddOverrideUpgradeMealPrice(e.target.value)}
                                            type="number"
                                            min={1}
                                            placeholder="Price"
                                            aria-label="Price"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={2}>
                                    <Button className="w-100" onClick={addDateOverridePriceHandler}>Save</Button>
                                </Col>
                            </Row>
                            <hr />
                            {overridePrices && overridePrices.map(overridePrice => (
                                <Row key={overridePrice.id} className="mt-1">
                                    <Col md={5}>
                                        <p className="align-middle text-center">{overridePrice.date.toString().split("T")[0]}</p>
                                    </Col>
                                    <Col md={5}>
                                        <p className="align-middle text-center">${overridePrice.price}</p>
                                    </Col>
                                    <Col md={2}>
                                        <Button variant="danger" onClick={() => deleteDateOverridePriceHandler(overridePrice.id)}>Delete</Button>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <CardHeader>
                            Prices per day
                        </CardHeader>
                        <Card.Body>
                            <Row>
                                <h6 className="text-secondary text-center">Add/Update Date price</h6>
                                <Col md={5}>
                                    <FormGroup>
                                        <FormControl value={addDayPriceDate}
                                             onChange={e => setAddDayPriceDate(e.target.value)} type='date' placeholder="Date"></FormControl>
                                    </FormGroup>
                                </Col>
                                <Col md={5}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                        <Form.Control
                                            value={addDayPrice}
                                            onChange={e => setAddDayPrice(e.target.value)}
                                            type="number"
                                            min={1}
                                            placeholder="Price"
                                            aria-label="Price"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={2}>
                                    <Button className="w-100" onClick={addDayPriceHandler}>Save</Button>
                                </Col>
                            </Row>
                            <hr />
                            {dayPrices && dayPrices.map(dayPrice => (
                                <Row key={dayPrice.id} className="mt-1">
                                    <Col md={5}>
                                        <p className="align-middle text-center">{dayPrice.date.toString().split("T")[0]}</p>
                                    </Col>
                                    <Col md={5}>
                                        <p className="align-middle text-center">${dayPrice.price}</p>
                                    </Col>
                                    <Col md={2}>
                                        <Button variant="danger" onClick={() => deleteDayPriceHandler(dayPrice.id)}>Delete</Button>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Settings;