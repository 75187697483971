import { useState, useRef, useEffect } from 'react';

const getScreenSize = () => ({
    width: document.body.clientWidth,
    height: document.body.clientHeight,
    isMobile: document.body.clientWidth <= 700,
});

function getAllDaysInMonth(year, month) {
    const _date = new Date(year, month, 1);
    const date = new Date(_date.getTime() - (_date.getTimezoneOffset() * 60000));

    const dates = [];

    while (date.getMonth() === month) {
        const p = new Date(date);
        p.setHours(12);
        dates.push(p);
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

export const getAllDatesWithinYear = year => {

    return getDates(new Date("1/1/" + year), new Date("12/31/" + year));
    // let dates = [];
    // for (let i = 0; i < 12; i++) {
    //     dates.push(...getAllDaysInMonth(year, i));
    // }

    // return dates;
}

export const convertDateString = (str) => {
    let datePart = str.split('T')[0];
    //2022-01-03
    let parts = datePart.split('-');
    let newDateStr = `${parts[1]}/${parts[2]}/${parts[0]}`;
    //console.log(str, datePart, newDateStr);
    return newDateStr;
}

export const CAMPAIGN_ID = 9503;


export const getMost = (arr) => {
    return arr.sort((a, b) =>
        arr.filter(v => v === a).length
        - arr.filter(v => v === b).length
    ).pop();
}

export function useScreenSize() {
    const [screenSize, setScreenSize] = useState(getScreenSize);
    const timeout = useRef();

    useEffect(() => {
        function onResize() {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => setScreenSize(getScreenSize), 250);
        }

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return screenSize;
}