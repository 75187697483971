import './eventContent.css';
import { HDate } from '@hebcal/core';

export const EventContent = ({
    hebrewInfo,
    isMobile,
    allowDonate,
    date,
    dayClickedWrapper,
    dayPrices,
    defaultDayPrice,
    donorName,
    donationText }) => {

    const dayCellContextRenderer = () => {
        let gematria = new HDate(date).renderGematriya();
        return `${gematria.substring(0, gematria.length - 5)}`;
    }

    return hebrewInfo != null ? (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p className="text-center m-0 p-0 parsha-info" style={{ fontSize: isMobile ? '9px' : '' }}>{hebrewInfo.infoText}</p>
                </div>
            </div>
        </div>) : (allowDonate ? (
            <div className="container donate-container" onClick={() => dayClickedWrapper(date)}>
                <div className="row">
                    <div className="col-6">
                        {/* <img src={calendarIcon} width={22} height={22} /> */}
                    </div>
                    <div className="col-6 hebrew-full-date">
                        <p className="float-end" style={{ fontSize: isMobile ? '9px' : '' }}>{dayCellContextRenderer()}</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 m-0 p-0">
                        <p className="text-center sponsor-text mb-0" style={{ fontSize: isMobile ? '9px' : '' }}>Sponsor</p>
                        <h3 className="text-center price-text m-0 mb-2" style={{ fontSize: isMobile ? '12px' : '' }}>${dayPrices && Number(dayPrices.find(x => x.date.split("T")[0] == date)?.price ?? defaultDayPrice).toLocaleString()}</h3>
                    </div>
                </div>
            </div>
        ) :
            (
                <div className="container donate-card">
                    <div className={`row ${isMobile && "mt-4"}`}>
                        {!isMobile && (
                            <div className="col-6">
                                <p><b>נמכר</b></p>
                            </div>
                        )}
                        <div className="col-6">
                            <p className="float-end sponsored-by" style={{ fontSize: isMobile ? '9px' : '' }}>{dayCellContextRenderer()}</p>
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div className='col-12 p-0'>
                            <p className="sponsored-by m-0 text-center wrap-text" style={{ fontSize: isMobile ? '9px' : '' }}>נתנדב ע"י</p>
                            <p className="m-0 wrap-text text-center" style={{ fontSize: isMobile ? '9px' : '' }}><b>{donorName}</b></p>
                            {!isMobile && (<p className="sponsored-by m-0 wrap-text">{donationText}</p>)}
                        </div>
                    </div>

                </div>
            ));
}