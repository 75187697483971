export const dayNames = {
    "Sat": "שבת",
    "Fri": "שישי",
    "Thu": "חמישי",
    "Wed": "רביעי",
    "Tue": "שלישי",
    "Mon": "שני",
    "Sun": "ראשון",
}

export const offlineFieldNames = {
    "firstName": "offline_donation[fname]",
    "lastName": "offline_donation[lname]",
    "publicName": "offline_donation[public_name]",
    "email": "offline_donation[email]",
    "phone": "offline_donation[phone]",
    "note": "offline_donation[comment]",
    "price": "offline_donation[amount]",
    "dateEn": "offline_donation[sponsor_date_en]",
    "dateHe": "offline_donation[sponsor_date_he]"
}


export const onlineFieldNames = {
    "firstName": "external_data[fname]",
    "lastName": "external_data[lname]",
    "publicName": "external_data[public_name]",
    "email": "external_data[email]",
    "phone": "external_data[phone]",
    "note": "external_data[comment]",
    "price": "external_data[amount]",
    "dateEn": "external_data[sponsor_date_en]",
    "dateHe": "external_data[sponsor_date_he]"
}

export const attributionMapping = [
    'לע"נ',
    'לזכות',
    'לרפואת'
]

export const hebrewMonthNames = [
    "ניסן",
    "אייר",
    "סיון",
    "תמוז",
    "אב",
    "אלול",
    "תשרי",
    "חשון",
    "כסלו",
    "שבט",
    "טבת",
    "אדר",
    "אדר ב׳"
]

export const hebrewLetters = "אבגדהוזחטיכלמנסעפצקרשת";